/*
 * @Date: 2023-01-14 19:48:43
 * @LastEditTime: 2023-01-20 18:49:54
 */
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name:'Home',
    component: () => import('@/views/home'),
    meta: { title: 'Home',class:'home' },
  },
  {
    path: '/about',
    name:'About',
    component: () => import('@/views/about'),
    meta: { title: 'About',class:'about' },
  },
  {
    path: '/job',
    name:'Job',
    component: () => import('@/views/job'),
    meta: { title: 'Job',class:'job' },
  },
  {
    path: '/job',
    name:'Job',
    component: () => import('@/views/job'),
    meta: { title: 'Job',class:'job' },
  },
  {
    path: '/mall',
    name:'Mall',
    component: () => import('@/views/mall'),
    meta: { title: 'Mall',class:'mall' },
  },
  {
    path: '/mall/detail/:id',
    name:'MallDetail',
    component: () => import('@/views/mall/detail'),
    meta: { title: 'MallDetail',class:'mall-detail' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
