<!--
 * @Date: 2022-08-09 21:23:49
 * @LastEditTime: 2023-01-31 12:55:15
-->
<template>
  <div :class="'header '+this.$route.meta.class">
    <header class="block-center col-flex middle between">
      <div class="col-flex middle">
        <a href="/" class="logo img-max">
          <img :src="logo" alt="">
        </a>
        <app-menu></app-menu>
      </div>
      <a-button @click="openModal" class="contact" ghost :type="btn">申请试用</a-button>
    </header>
  </div>
  <app-modal ref="appModal"></app-modal>
</template>

<script>
import AppMenu from './menu'
import AppModal from '@/views/components/modal'
export default {
  name: 'AppHeader',
  data () { 
    return {
      nowName:'',
      visible:false,
    }
  },
  components:{
    AppMenu,
    AppModal
  },
  computed: {
    logo(){
      const name = ['MallDetail']
      return name.includes(this.$route.name)?require('@/assets/img/logo-blue.svg'):require('@/assets/img/logo.svg')
    },
    btn(){
      const name = ['MallDetail']
      return name.includes(this.$route.name)?'primary':''
    }
  },
  watch: {
    
  },
  mounted () { 
    
  },
  methods: {
    openModal () {
      this.$refs.appModal.open()
    }
  }
}
</script>
<style lang="less" scoped>
  .header{
    padding: .08rem .24rem;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    z-index: 200;
    &.mall-detail{
      border-bottom: 1px solid #dedede;
    }
    header{
      max-width: 14rem;
    }
    .logo{
      display: block;
      margin-right: 1.2rem;
      img{
        height:.38rem;
        display: block;
      }
    }
  }
  @media (max-width: 750px){
    .header{
      padding: .16rem .24rem;
      width:100%;
      position:absolute;
      top:0;
      left:0;
      z-index: 200;
      header{
        width: auto;
      }
      .logo{
        display: block;
        margin-right: .6rem;
        img{
          height:.48rem;
          display: block;
        }
      }
    }
  }
</style>