<!--
 * @Date: 2023-01-21 20:02:39
 * @LastEditTime: 2023-01-31 11:42:01
-->
<template>
  <a-modal wrapClassName="apply-modal" v-model:visible="visible">
    <h3>欢迎咨询，请填写相关信息</h3>
    <small>在收到您提交后的三个工作日内，我们专属顾问会联系您。</small>
    <h4>您的联系方式</h4>
    <a-form :model="form" name="form" ref="form" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16 }" :rules="rules">
      <a-form-item label="公司名称：" name="companyname">
        <a-input v-model:value="form.companyname" placeholder="(必填)请输入公司名称"/>
      </a-form-item>
      <a-form-item label="姓名：" name="name">
        <a-input v-model:value="form.name" placeholder="(必填)请输入您的姓名"/>
      </a-form-item>
      <a-form-item label="邮箱：" name="mail">
        <a-input v-model:value="form.mail" placeholder="(选填)请输入邮箱地址"/>
      </a-form-item>
      <a-form-item label="手机号：" name="phone">
        <a-input v-model:value="form.phone" placeholder="(必填)请输入手机号"/>
      </a-form-item>
      <h4>您的AI算法需求描述</h4>
      <a-form-item label="需求描述：" name="details">
        <a-textarea v-model:value="form.details" placeholder="(必填)需求描述" :rows="4"/>
      </a-form-item>
      <a-form-item class="no-label">
        <template #label>
          <span>1</span>
        </template>
        <a-button type="primary" block @click="postInfo">提交</a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import axios from 'axios'
import { message } from 'ant-design-vue';
export default {
  data(){
    function vMobilePhone(rule, value, callback) {
      if (value) {
        if (!/^\d{11}$/.test(value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      } else {
        callback(new Error('手机号不能为空'))
      }
    }
    return{
      visible:false,
      form:{
        companyname:'',
        name:'',
        mail:'',
        phone:'',
        details:''
      },
      rules:{
        companyname:[{required: true, message:'必填', trigger: 'blur' }],
        name:[{required: true, message:'必填', trigger: 'blur' }],
        phone:[{required: true,validator:vMobilePhone, trigger: 'blur' }],
        details:[{required: true, message:'必填', trigger: 'blur' }]
      }
    }
  },
  mounted(){

  },
  methods:{
    open(){
      this.visible = true
    },
    closed(){
      this.visible = false
    },
    postInfo(){
      console.log('form',this.form)
      this.$refs['form'].validate().then(valid => {
        if(valid){
          axios.post('/login',this.form).then(res=>{
            console.log(res.data)
            if(res.data.code == '10000'){
              message.success('申请成功')
              this.closed()
            }
          }).catch(err=>{
            console.log('err',err)
          })
        }else{
          console.log('filled')
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .apply-modal{
    .ant-modal-body{
      padding: 0 40px;
      .no-label{
        font-size: 0;
        &+::before{
          display: none;
        }
      }
    }
    h3{
      font-size: 24px;
    }
    small{
      font-size: .14rem;
      color: rgba(0,0,0,.65);
    }
    h4{
      font-size: 18px;
      margin-top: .32rem;
      margin-bottom: .16rem;
    }
    .footer{
      .ant-btn{
        width:3rem;
      }
    }
  }
</style>