<!--
 * @Descripttion:
 * @Author: yang
 * @Date: 2020-04-14 17:16:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-01-30 22:08:03
-->
<template>
  <div class="menu">
    <ul v-if="noSp" class="pc-menu col-flex">
      <li><router-link to="/">产品</router-link></li>
      <li>
        <a-popover placement="bottomLeft" trigger="hover">
          <template #content>
            <div class="qust-mall">
              <a class="hot" href="">
                <h3>自动化AI算法超市</h3>
                <p>多快好省！200+算法模型轻松部署至云端、各种边缘计算或者终端设备。</p>
              </a>
              <a-row class="hot-nav mt-2" :gutter="[32,16]">
                <a-col :span="8">
                  <dl>
                    <dt>
                      <setting-filled class="icon" />
                      <b>智慧工业</b>
                      <right-outlined />
                    </dt>
                    <dd><a href="/mall/detail/d87bc76b">铝板缺陷检测</a></dd>
                    <dd><a href="/mall/detail/a90d7f9d">包裹移动识别</a></dd>
                    <dd><a href="/mall/detail/1f9d5734">包裹爆仓识别</a></dd>
                    <dd><a href="/mall/detail/3357a04e">动作捕捉</a></dd>
                    <dd><a href="/mall/detail/3c1f11d8">刀闸状态智能识别</a></dd>
                  </dl>
                </a-col>
                <a-col :span="8">
                  <dl>
                    <dt>
                      <home-filled class="icon" />
                      <b>智慧城市</b>
                      <right-outlined />
                    </dt>
                    <dd><a href="/mall/detail/a38b6d12">宠物牵绳识别</a></dd>
                    <dd><a href="/mall/detail/b38c257a">人员扶扶手识别</a></dd>
                    <dd><a href="/mall/detail/6b88fb97">口罩识别</a></dd>
                    <dd><a href="/mall/detail/f0275742">监控室脱岗检测</a></dd>
                    <dd><a href="/mall/detail/bd16e5be">游摊小贩识别</a></dd>
                  </dl>
                </a-col>
                <a-col :span="8">
                  <dl>
                    <dt>
                      <mac-command-filled class="icon" />
                      <b>智慧物联</b>
                      <right-outlined />
                    </dt>
                    <dd><a href="/mall/detail/969b1e84">猫狗识别</a></dd>
                    <dd><a href="/mall/detail/353d8470">电动车进电梯识别</a></dd>
                  </dl>
                </a-col>
                <a-col :span="8">
                  <dl>
                    <dt>
                      <shop-filled class="icon" />
                      <b>智慧零售</b>
                      <right-outlined />
                    </dt>
                    <dd><a href="/mall/detail/ac15df36">玩手机识别</a></dd>
                    <dd><a href="/mall/detail/f685656e">表情识别</a></dd>
                  </dl>
                </a-col>
                <a-col :span="8">
                  <dl>
                    <dt>
                      <codepen-square-filled class="icon" />
                      <b>智慧农业</b>
                      <right-outlined />
                    </dt>
                    <dd><a href="/mall/detail/56c896df">辣椒病虫害识别</a></dd>
                    <dd><a href="/mall/detail/bdf11a39">葡萄病虫害识别</a></dd>
                    <dd><a href="/mall/detail/6283d6e8">玉米病虫害识别</a></dd>
                    <dd><a href="/mall/detail/7b0ff7d5">大葱病虫害识别</a></dd>
                    <dd><a href="/mall/detail/c30fe4b4">茄子病虫害识别</a></dd>
                  </dl>
                </a-col>
              </a-row>
            </div>
          </template>
          <router-link to="/mall">算法商城</router-link>
        </a-popover>
      </li>
      <li>
        <a-popover>
          <template #content>
            <ul class="sub-menu">
              <li><router-link to="/job">加入我们</router-link></li>
              <li><router-link to="/about">公司介绍</router-link></li>
            </ul>
          </template>
          <a href="javascript:void(0)">关于我们</a>
        </a-popover>
      </li>
    </ul>
    <a-popover v-else v-model="visible" title="" trigger="click" placement="bottomLeft">
      <template #content>
        <a-menu class="app-menu" v-model="current" mode="vertical">
          <a-menu-item key="product"><router-link to="/">产品</router-link></a-menu-item>
          <a-menu-item key="mall"><router-link to="/mall">算法商城</router-link></a-menu-item>
          <a-sub-menu key="sub2">
            <template #title>关于我们</template>
            <a-menu-item key="job"><router-link to="/job">加入我们</router-link></a-menu-item>
            <a-menu-item key="about"><router-link to="/about">公司介绍</router-link></a-menu-item>
          </a-sub-menu>
        </a-menu>
      </template>
      <a-button ghost :type="ttc">
        <menu-outlined />
      </a-button>
    </a-popover>
  </div>
</template>
<script setup>
  import { MenuOutlined,SettingFilled,RightOutlined,MacCommandFilled,HomeFilled,ShopFilled,CodepenSquareFilled } from '@ant-design/icons-vue';
  import { useRouter } from 'vue-router'
  import { computed } from 'vue'
  const current = ['home']
  const collapsed = true
  const mode = 'horizontal'
  const meunWidth = '8.22rem'
  const visible = false
  const route = useRouter();
  let type = ''
  let noSp = true
  const device = localStorage.getItem('device')
  let ttc = computed(()=>{
    const name = ['MallDetail']
    return name.includes(route.currentRoute.value.name)?'primary':''
  })
  let searchValue = ''
  switch(device){
    case 'pc':
      noSp = true
      break
    case 'mobile':
      noSp = false
      break
  }
  
</script>
<style lang="less" scoped>
.pc-menu{
  &>li{
    margin-left: .16rem;
    padding-right: .16rem;
    font-size: .18rem;
    color: #fff;
    &>a{
      color:#fff;
    }
  }
}
.mall-detail{
  .pc-menu{
    &>li{
      color: #000;
      &>a{
        color:#000;
        &:hover{
          color:rgba(35, 64, 196, 1);
        }
      }
    }
  }
}
.sub-menu{
  li{
    padding: .08rem .24rem;
    a{
      color:#000
    }
  }
}
.qust-mall{
  width: 8.4rem;
  padding: .16rem 0;
  .hot{
    display: block;
    padding:.16rem;
    border-radius: 4px;
    color:#000;
    background-color: #f2f2f2;
    h3{
      font-size: .16rem;
      font-weight: 600;
    }
    p{
      color: #666;
    }
    &:hover{
      box-shadow: 0px 4px 6px 0px rgba(79,79,94,0.25);
      transition: all .2s ease-in;
      background-color: #fff;
      h3{
        color:rgba(35, 64, 196, 1);
      }
    }
  }
  .hot-nav{
    dt{
      font-size: .16rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dedede;
      padding-bottom: .08rem;
      b{
        margin-left: .08rem;
        margin-right: .16rem;
      }
      .icon{
        color: rgba(0,0,0,0.55);
      }
    }
    dd{
      padding: .04rem .24rem;
      a{
        color:rgba(0,0,0,0.55);
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
