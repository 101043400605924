/*
 * @Date: 2023-01-14 19:48:43
 * @LastEditTime: 2023-01-14 21:17:31
 */

import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.less';
import '@/less/reset-antd.less'
import '@/less/index.less'

const app = createApp(App);

app.use(Antd).use(store).use(router).mount('#app');