<!--
 * @Date: 2022-08-09 21:23:49
 * @LastEditTime: 2023-03-02 18:36:35
-->
<template>
  <div class="wrap block-center">
    <app-header></app-header>
    <router-view />
    <app-footer></app-footer>
  </div>
  <div class="right-bar">
    <a-popover placement="left">
      <template #content>
        <p>180 - 2242 - 0147</p>
      </template>
      <span class="item phone">
        <phone-filled class="icon" />
        <small>电话</small>
      </span>
    </a-popover>
    <span class="item mesg" @click="openModal">
      <message-filled class="icon" />
      <small>商务合作</small>
    </span>
    <!-- <span class="item mesg">
      <message-filled class="icon" />
      <small><a href="https://affim.baidu.com/cps/chat?siteId=18781313&userId=45307295&siteToken=fc229e21dbdcd5a9f286347372d3d713&cp=laibei&cr=laibei.com&cw=">在线咨询</a></small>
    </span> -->
    <a-popover placement="left">
      <template #content>
        <div class="wechat-img">
          <img src="@/assets/img/r-code.png" alt="">
        </div>
      </template>
      <span class="item wechat">
        <qrcode-outlined class="icon" />
        <small>微信</small>
      </span>
    </a-popover>
    <span class="item top" @click="toTop">
      <up-outlined class="icon" />
      <small>
        TOP <br>
        返回顶部
      </small>
    </span>
  </div>
  <app-modal ref="appModal"></app-modal>
</template>

<script>
import AppHeader from '@/components/header'
import AppFooter from '@/components/footer'
import AppModal from '@/views/components/modal'
import {PhoneFilled,MessageFilled,QrcodeOutlined,UpOutlined} from '@ant-design/icons-vue'
export default {
  components: {
    AppHeader,
    AppFooter,
    PhoneFilled,
    MessageFilled,
    QrcodeOutlined,
    UpOutlined,
    AppModal
  },
  created () { 
    if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      localStorage.setItem('device','mobile')
    } else {
      localStorage.setItem('device','pc')
    }
  },
  mounted () {
    
  },
  methods:{
    toTop(){
      console.log(document.body.scrollTop)
      document.documentElement.scrollTop = 0
    },
    openModal () {
      this.$refs.appModal.open()
    }
  }
}
</script>
<style lang="less" scoped>
.right-bar{
  position: fixed;
  bottom: 2%;
  right: 0;
  z-index: 1024;
  .item{
    display: block;
    padding: .08rem;
    background-color: rgba(0,0,0,.9);
    text-align: center;
    color:#fff;
    margin-bottom: 1px;
    cursor: pointer;
    .icon{
      font-size: .24rem;
      margin-bottom: .08rem;
    }
    small{
      display: block;
      text-align: center;
      font-size: .14rem;
      a{
        color: #fff;
      }
    }
    &.top{
      .icon{
        margin-bottom: 0;
      }
    }
  }
}
.wechat-img{
  img{
    width: 1.6rem;
  }
}
@media (max-width: 750px){
  .right-bar{
    .item{
      padding: .16rem;
    }
  }
}
</style>