<!--
 * @Date: 2022-08-09 21:23:49
 * @LastEditTime: 2023-02-02 19:52:40
-->
<template>
  <footer class="footer text-white">
    <article class="column col-flex xs-block between top">
      <div class="dl-group">
        <dl class="col-flex top">
          <dt>产品信息</dt>
          <dd><a href="/">产品信息</a></dd>
          <dd><a href="/mall">算法商城</a></dd>
        </dl>
        <dl class="col-flex top mt-3">
          <dt>关于我们</dt>
          <dd><a href="/job">加入我们</a></dd>
          <dd><a href="/about">关于我们</a></dd>
        </dl>
        <dl class="col-flex top mt-3">
          <dt>商务咨询</dt>
          <dd>
            <p>
              180 2242 0147 <br>
              contact@jisulaibei.com
            </p>
          </dd>
        </dl>
      </div>
      <div class="ads">
        <h3>公司地址</h3>
        <p class="mt-1">
          深圳总部：深圳市南山区桃源街道南山云谷园区二期6栋4层
        </p>
      </div>
      <div class="rcode xs-mt-4 xs-text-center">
        <h3>关注我们</h3>
        <div class="img-group mt-1">
          <img src="@/assets/img/r-code.png" alt="">
        </div>
      </div>
    </article>
    <p class="text-center mt-3">版权所有【深圳市来呗科技有限公司】<a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2023007015号</a></p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data () { 
    return {
      nowName:''
    }
  },
  computed: {
    
  },
  watch: {
    
  },
  mounted () { 
    
  },
  methods: {
    
  }
}
</script>
<style lang="less" scoped>
  .footer{
    background-color: #000;
    padding: .4rem 0;
    dl{
      dt{
        margin-right: .8rem;
      }
      dd{
        margin-right: .6rem;
      }
    }
    p{
      line-height: 2;
    }
    a,p{
      color: rgba(255,255,255,.6);
    }
    a:hover{
      text-decoration: underline;
    }
    article{
      padding-bottom: .3rem;
      border-bottom: 1px solid rgba(199, 214, 246, .46);
    }
    .rcode{
      h3{
        font-size: 14px;
      }
      img{
        width: 1.6rem;
      }
    }
  }
   @media (max-width: 750px){
    .home-worth{
      h3{
        font-size: .26rem;
      }
    }
  }
</style>